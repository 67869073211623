import React, { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface AuthTitleProps {
  title: string;
  subTitle: ReactNode;
}

export default function AuthTitle({ title, subTitle }: AuthTitleProps) {
  return (
    <Stack spacing={1}>
      <Typography variant="h4" textAlign="center" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="body1" textAlign="center" color="text.secondary">
        {subTitle}
      </Typography>
    </Stack>
  );
}
