'use client';
import React, { PropsWithChildren } from 'react';
import {
  useForm,
  UseFormReturn,
  FieldValues,
  UseFormProps,
  FormProvider,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';

export interface FormProps<TFormValues extends FieldValues> extends UseFormProps<TFormValues> {
  onSubmit: (data: TFormValues) => void | Promise<void>;
  schema?: ObjectSchema<any>;
}

export function Form<TFormValues extends FieldValues>({
  children,
  onSubmit,
  schema,
  ...formConfig
}: PropsWithChildren<FormProps<TFormValues>>) {
  const methods = useForm<TFormValues>({
    ...formConfig,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
}

export type FormContextType<TFormValues extends FieldValues> = UseFormReturn<TFormValues>;

export default Form;
