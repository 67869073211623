import React from 'react';
import { Box, Button, CircularProgress, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBtn = styled(Button)(() => ({
  backgroundColor: '#FF5C00',
  textTransform: 'capitalize',
  color: 'white',
  fontSize: '16px',
  padding: '8px',
  height: '52px',
  marginTop: '24px !important',
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: '#F45B04',
  },
}));

export default function SubmitButton({
  disabled,
  loading,
  onClick,
  children,
  type = 'submit',
  sx,
}: {
  children: React.ReactNode;
  onClick?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit' | 'button' | 'reset';
  sx?: SxProps<Theme>;
}) {
  return (
    <StyledBtn 
      onClick={onClick} 
      disabled={disabled} 
      type={type}
      sx={sx}
    >
      {loading ? (
        <Box sx={{ color: '#fff', height: '50%' }}>
          <CircularProgress color="inherit" size={24} />
        </Box>
      ) : (
        children
      )}
    </StyledBtn>
  );
}
