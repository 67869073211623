'use client';
import React, { useState } from 'react';
import { useRouter } from 'nextjs-toploader/app';
import { signIn, SignInResponse } from 'next-auth/react';
import * as yup from 'yup';
import { Stack, Typography } from '@mui/material';
import AuthTitle from '@/app/modules/auth/components/auth-title';
import TextInput from '@/app/modules/auth/components/text-input';
import MFASetup from '@/app/modules/auth/components/mfa-setup';
import SubmitButton from '@/app/modules/auth/components/submit-button';
import Form from '@/app/modules/common/components/form';

const defaultValues = { username: '', password: '' };

interface LoginForm {
  username: string;
  password: string;
}

interface ExtendedSignInResponse extends SignInResponse {
  session?: string;
  secretCode?: string;
}

const schema = yup.object({
  username: yup
    .string()
    .trim()
    .email('Must be valid email')
    .required('Required Field'),
  password: yup.string().trim().required('Required Field'),
});

export default function SignInPageTemplate() {
  const router = useRouter();
  const [formStep, setFormStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [credentials, setCredentials] = useState<LoginForm | null>(null);
  const [code, setCode] = useState('');
  const [mfaSecretCode, setMfaSecretCode] = useState<string>('');
  const [session, setSession] = useState<string>('');
  const [newPassword, setNewPassword] = useState('');

  const [error, setError] = useState('');

  const handleSubmit = async (form: LoginForm) => {
    const payload = {
      redirect: false,
      ...form,
    };

    setError('');
    setLoading(true);
    try {
      console.log('Attempting sign in with payload:', payload);
      const res = await signIn('credentials', payload) as ExtendedSignInResponse;
      console.log('Sign in response:', res);

      if (!res) {
        console.error('No response received from authentication server');
        setError('No response from authentication server');
        return;
      }

      // If we have a URL, it means we're being redirected (success case)
      if (res.url) {
        console.log('Authentication successful, redirecting...');
        router.push('/portal/overview');
        return;
      }

      // If we have an error, handle it
      if (res.error) {
        console.log('Handling error case:', res.error);
        try {
          // Try to parse the error as JSON
          const errorData = JSON.parse(res.error);
          console.log('Parsed error data:', errorData);

          switch(errorData.error) {
            case 'NEW_PASSWORD_REQUIRED':
              console.log('Setting form step to 3 for new password');
              setCredentials(form);
              setSession(errorData.session);
              setFormStep(3);
              break;
            case 'MFA_SETUP_REQUIRED':
              console.log('Setting form step to 2 for MFA setup');
              setCredentials(form);
              setMfaSecretCode(errorData.secretCode);
              setSession(errorData.session);
              setFormStep(2);
              break;
            case 'MFA_REQUIRED':
              console.log('Setting form step to 1 for MFA verification');
              setCredentials(form);
              setSession(errorData.session);
              setCode('');
              setFormStep(1);
              break;
            default:
              console.error('Unhandled error case:', errorData.error);
              setError(errorData.error || 'Authentication failed');
          }
        } catch (parseError) {
          // If it's not JSON, treat it as a plain error message
          console.error('Failed to parse error response:', parseError);
          setError(res.error);
        }
        return;
      }

      // If we get here without a URL or error, something's wrong
      console.error('Unexpected response:', res);
      setError('Unexpected response from server');
    } catch (e: any) {
      console.error('Sign in error:', e);
      setError(e?.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSubmit = async () => {
    setError('');
    setLoading(true);

    try {
      const res = await signIn('credentials', {
        username: credentials?.username,
        password: credentials?.password,
        code: code,
        session: session,
        redirect: false,
      });

      if (res?.error) {
        try {
          const errorData = JSON.parse(res.error);
          setError(errorData.error || 'MFA verification failed');
        } catch {
          setError('MFA verification failed');
        }
        return;
      }

      router.push('/portal/overview');
    } catch (err) {
      setError('Failed to verify MFA code');
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSetupVerify = async (verificationCode: string) => {
    setError('');
    setLoading(true);
    try {
      console.log('Verifying MFA setup with code:', verificationCode);
      const res = await signIn('credentials', {
        ...credentials,
        mfaSetupCode: verificationCode,
        session: session,
        secretCode: mfaSecretCode,
        redirect: false,
      }) as ExtendedSignInResponse;

      console.log('MFA setup verification response:', res);

      if (!res) {
        throw new Error('No response from authentication server');
      }

      if (res.error) {
        try {
          const errorData = JSON.parse(res.error);
          switch(errorData.error) {
            case 'MFA_REQUIRED':
              setSession(errorData.session);
              setCode('');
              setFormStep(1);
              return;
            default:
              throw new Error(errorData.error || 'MFA setup verification failed');
          }
        } catch (parseError) {
          throw new Error(res.error);
        }
      }

      if (res.url) {
        router.push('/portal/overview');
        return;
      }

      throw new Error('Unexpected response from server');
    } catch (err: unknown) {
      console.error('MFA setup verify error:', err);
      if (err instanceof Error) {
        setError(err.message);
      } else if (typeof err === 'string') {
        setError(err);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNewPassword = async (newPassword: string) => {
    setError('');
    setLoading(true);

    try {
      console.log('Submitting new password');
      const res = await signIn('credentials', {
        ...credentials,
        newPassword,
        session: session,
        redirect: false,
      }) as ExtendedSignInResponse;

      if (!res) {
        setError('No response from authentication server');
        return;
      }

      if (res.error) {
        try {
          const errorData = JSON.parse(res.error);
          switch(errorData.error) {
            case 'MFA_SETUP_REQUIRED':
              setMfaSecretCode(errorData.secretCode);
              setSession(errorData.session);
              setFormStep(2);
              break;
            case 'MFA_REQUIRED':
              setSession(errorData.session);
              setCode('');
              setFormStep(1);
              break;
            default:
              setError(errorData.error || 'Failed to set new password');
          }
        } catch (parseError) {
          setError(res.error);
        }
        return;
      }

      if (res.url) {
        router.push('/portal/overview');
      }
    } catch (e: any) {
      console.error('New password error:', e);
      setError(e?.message || 'Failed to set new password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3} sx={{ width: '100%', maxWidth: 400 }}>
      <AuthTitle 
        title="Sign In"
        subTitle="Welcome back! Please enter your details to continue."
      />
      {formStep === 0 && (
        <Form<LoginForm>
          defaultValues={defaultValues}
          schema={schema}
          onSubmit={handleSubmit}
        >
          <Stack spacing={3}>
            <TextInput
              name="username"
              label="Email"
              type="text"
              autoComplete="email"
              inputProps={{ style: { fontFamily: 'monospace' } }}
            />
            <TextInput
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
            />
            {error && (
              <Typography color="error" variant="body2" textAlign="center">
                {error}
              </Typography>
            )}
            <SubmitButton loading={loading}>Sign In</SubmitButton>
          </Stack>
        </Form>
      )}

      {formStep === 1 && (
        <Stack
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            if (code.length === 6) {
              handleMfaSubmit();
            }
          }}
          spacing={3}
          alignItems="center"
          sx={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}
        >
          <Typography variant="h5" textAlign="center" fontWeight="bold">
            Two-Factor Authentication
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            Enter the 6-digit code from your authenticator app
          </Typography>
          <div style={{ width: '100%' }}>
            <input
              type="text"
              maxLength={6}
              value={code}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                if (value.length <= 6) {
                  setCode(value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && code.length === 6) {
                  e.preventDefault();
                  handleMfaSubmit();
                }
              }}
              style={{
                width: '100%',
                padding: '16px',
                fontSize: '24px',
                letterSpacing: '8px',
                textAlign: 'center',
                border: '2px solid #e0e0e0',
                borderRadius: '8px',
                backgroundColor: '#f5f5f5',
                outline: 'none',
                transition: 'all 0.2s ease-in-out',
              }}
              placeholder="000000"
              autoFocus
            />
          </div>
          {error && (
            <Typography color="error" variant="body2" textAlign="center">
              {error}
            </Typography>
          )}
          <SubmitButton
            type="submit"
            loading={loading}
            disabled={code.length !== 6}
            sx={{ 
              width: '100%',
              height: '48px !important',
              marginTop: '16px !important'
            }}
          >
            Verify Code
          </SubmitButton>
        </Stack>
      )}

      {formStep === 2 && (
        <MFASetup
          secretCode={mfaSecretCode}
          onVerify={handleMfaSetupVerify}
          onError={setError}
        />
      )}

      {formStep === 3 && (
        <Form
          defaultValues={{ newPassword: '' }}
          schema={yup.object({
            newPassword: yup.string().required('Required Field'),
          })}
          onSubmit={(data) => handleNewPassword(data.newPassword)}
        >
          <Stack spacing={3}>
            <Typography variant="h6" textAlign="center">
              Set New Password
            </Typography>
            <Typography variant="body2" textAlign="center">
              Your password needs to be changed. Please enter a new password.
            </Typography>
            <TextInput
              name="newPassword"
              label="New Password"
              type="password"
              autoComplete="new-password"
            />
            {error && (
              <Typography color="error" variant="body2" textAlign="center">
                {error}
              </Typography>
            )}
            <SubmitButton loading={loading}>Change Password</SubmitButton>
          </Stack>
        </Form>
      )}
    </Stack>
  );
}
