import React from 'react';
import { Box, Stack } from '@mui/material';

export interface PasscodeInputProps {
  code: string[];
  setCode: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function PasscodeInput({ code, setCode }: PasscodeInputProps) {
  const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 1) return; // Only allow single digit

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Auto-focus next input
    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name="code-${index + 1}"]`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
    }
  };

  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle backspace
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      const prevInput = document.querySelector(`input[name="code-${index - 1}"]`) as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
        const newCode = [...code];
        newCode[index - 1] = '';
        setCode(newCode);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const pastedCode = pastedData.slice(0, 6).split('');
    
    if (pastedCode.length === 6 && /^\d+$/.test(pastedData.slice(0, 6))) {
      setCode(pastedCode);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      onPaste={handlePaste}
    >
      {code.map((digit, index) => (
        <Box
          key={index}
          sx={{
            width: 40,
            height: 48,
          }}
        >
          <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            name={`code-${index}`}
            value={digit}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
              fontSize: '1.5rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              outline: 'none',
              caretColor: 'transparent',
            }}
            autoComplete="one-time-code"
          />
        </Box>
      ))}
    </Stack>
  );
}
