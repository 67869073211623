'use client';
import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import PasscodeInput from '../passcode-input';
import SubmitButton from '../submit-button';

interface MFASetupProps {
  secretCode: string;
  onVerify: (code: string) => Promise<void>;
  onError: (error: string) => void;
}

const MFASetup: React.FC<MFASetupProps> = ({ secretCode, onVerify, onError }) => {
  const [code, setCode] = useState<string[]>(Array(6).fill(''));
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onVerify(code.join(''));
    } catch (error) {
      onError('Failed to verify MFA code. Please try again.');
      setCode(Array(6).fill(''));
    } finally {
      setLoading(false);
    }
  };

  const qrCodeValue = `otpauth://totp/AcumenClientPortal:${encodeURIComponent(
    'user'
  )}?secret=${secretCode}&issuer=AcumenClientPortal`;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" align="center">
        Set Up Two-Factor Authentication
      </Typography>
      <Typography variant="body1" align="center">
        1. Download an authenticator app like Google Authenticator or Authy
      </Typography>
      <Typography variant="body1" align="center">
        2. Scan this QR code with your authenticator app
      </Typography>
      <Box display="flex" justifyContent="center" mb={2}>
        <QRCodeSVG value={qrCodeValue} size={200} />
      </Box>
      <Typography variant="body1" align="center">
        3. Enter the 6-digit code from your authenticator app
      </Typography>
      <PasscodeInput code={code} setCode={setCode} />
      <SubmitButton loading={loading}>Verify and Complete Setup</SubmitButton>
    </Stack>
  );
};

export default MFASetup;
